import { Checkbox } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import UsageRow from './UsageRow';

const UsageGroupRowData = ({
  usageTotal,
  usageRows,
  cyfAdmin,
  usageTotalChecked,
  usageTotalId,
  updateUsageTotalCheckBox,
  currentOrganisation,
  rowIndex,
  facilities,
  key,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [currentOrganisation]);

  const endDate = moment(usageTotal.endDate).format('DD/MM/YYYY');
  const startDate = moment(usageTotal.endDate).add(-1, 'year').add(1, 'day').format('DD/MM/YYYY');
  const facilityName = _.find(facilities, { id: usageTotal.facility })?.name;

  return (
    <React.Fragment>
      <tr key={key} className={`main_row-${rowIndex}`}>
        <td width='20%'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {cyfAdmin && <Checkbox checked={usageTotalChecked} onChange={() => updateUsageTotalCheckBox(usageTotalId)} />}
            <span className='usage-subentityName'>{usageTotal.subentityName}</span>
          </div>
        </td>
        <td className='usage-value' width='20%'>
          {!usageTotal.hasDifferentCurrencies && (
            <>
              {parseFloat(usageTotal.usageValue).toFixed(2)} {usageTotal.metricName}
            </>
          )}
        </td>
        <td className='usage-facility' width='15%'>
          {usageTotal?.facilityName || facilityName}
        </td>
        <td className='usage-period' width='25%'>
          {startDate} - {endDate}
        </td>
        <td className='usage-main-row-actions' width='20%'>
          {!expanded && (
            <button
              id='expand-button'
              onClick={() => setExpanded(true)}
              className='expand'
              type='button'
              title='Click to Expand'
              style={{ cursor: 'pointer', paddingLeft: 45, border: 0, background: 'inherit' }}>
              <i className='fa fa-plus-circle' />
            </button>
          )}
          {expanded && (
            <button
              onClick={() => setExpanded(false)}
              className='reduce'
              type='button'
              title='Click to Expand'
              id='reduce-button'
              style={{ cursor: 'pointer', paddingLeft: 45, border: 0, background: 'inherit' }}>
              <i className='fa fa-minus-circle' />
            </button>
          )}
        </td>
      </tr>
      {expanded &&
        usageRows?.map((row, i) => (
          <UsageRow row={row} facilityName={facilityName} key={row.id} i={rowIndex} index={row.id} hash={row.hash} />
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const { usageTotal } = props;

  const hash = usageTotal?.hash;

  const usageRows = _.sortBy(
    state?.usageData?.filter((item) => item.hash === hash),
    ['dateStart', 'dateEnd']
  );

  /* const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;

  const usageDetailLabels = usageTypeDetails?.reduce((acc, curr) => {
    acc[curr.type] = curr.displayName;
    return acc;
  }, {}); */

  return {
    usageRows,
    facilities: state?.facility?.list,
    usageTotalChecked: state?.admin?.checkboxes?.usageTotals?.[hash]?.checked || false,
    cyfAdmin: state?.profile?.details?.cyfAdmin,
    usageTotalId: hash,
    currentOrganisation: state.currentOrganisation,
    types: state?.metric?.types,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUsageTotalCheckBox: (usageTotalId) => dispatch(ADMIN_ACTIONS.updateUsageTotalCheckBox(usageTotalId)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(UsageGroupRowData));
